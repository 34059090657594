.wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
		/* background-image: linear-gradient(to top, var(--bgWorldBackground) , var(--bgWorldBackground1)); */
}



.navbar {
    position: absolute;
    width: 100%;
    height: 5.2%;
    left: 0;
    top: 0;

    background: var(--bgTopNavbar);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
    z-index: 3;
    opacity: var(--TopNavbarOpacity);
}

.company-logo1 {
    position: absolute;
    width: 15%;
    left: 1%;
    top: 0.8%;
    z-index: 3;
}

.company-logo2 {
	position: absolute;
	width: 10%;
	left: 17%;
	top: -1.7%;
	z-index: 3;
}

.divider1 {
    position: absolute;
    width: 0.0677%;
    height: 1.5833%;
    left: 15.505%;
    top: 2.50%;
    z-index: 3;
    background: var(--bgWhite);
}


.divider2 {
    position: absolute;
    width: 0.0677%;
    height: 1.5833%;
    left: 89.65%;
    top: 2.50%;
    z-index: 3;
    background: var(--bgWhite);
}

.mute {
    position: absolute;
    left: 95.5875%;
    top: 2%;
    
    width: 2%;
    aspect-ratio: 1;
}

.playPause {
    position: absolute;
    left: 93.7175%;
    top: 2%;
    
    width: 2%;
    aspect-ratio: 1;
}


.twt {
    position: absolute;
    left: 91.15%;
    right: 7.29%;
    top: 1.85%;
    
    width: 1.5625%;
    aspect-ratio: 1;
}

.lin {
    
    position: absolute;
    left: 93.02%;
    right: 5.42%;
    top: 1.85%;
    
    width: 1.5625%;
    aspect-ratio: 1;
}

.withclick {
    z-index: 4;
    cursor: pointer;
}
