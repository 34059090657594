@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {

  --fontWhite: #ffffff; 
  --fontToolbar: #CCCCCC; 
  --fontLight: #000000; 
  --fontBlack: #000000; 
  
  --bgWhite: #ffffff; 
  --bgGrey: #D9D9D9; 
  --bgSeparator: #808080; 
  --bgSeparator1: #85c0ff; 
  --bgShadow: #000000; 
  --bgMedium: #da1d3f;
  --bgLight: #ffd7de;
  --bgDark: #A00C26;

--fontFamily1:'Be Vietnam Pro';
--fontFamilyRobotoMedium:var(--fontFamily1);
--fontFamilyRobotoBold:var(--fontFamily1);

--fontWeightRegular:200;
--fontWeightMedium:400;
--fontWeightBold:500;
--contentLineHeight: 1.3;

  --opacityOpaque:1;
  --opacityHigh:.95;
  --opacityLow:0.9;
  --opacityVeryLow:0.4;

	--DataCardCTAFontSize: 0.8vw;

  --bgWorldBackground:var(--bgSeparator);
  --bgWorldBackground1:var(--bgSeparator1);

  --bagWelcomeDotCircleFill:var(--bgDark);
  --bagWelcomeDotCircle:var(--bgWhite);
  --bagWelcomeDotCircleStroke:var(--bgSeparator);
  --bgNextPreButton:var(--bgMedium);
  --bgNextPreButtonHover:var(--bgDark);

  --bgBottomToolbar: var(--bgDark);
  --BottomToolbarOpacity: var(--opacityHigh);
  --BottomToolbarFontFamily: var(--fontFamilyRobotoMedium);
  --BottomToolbarFontWeight: var(--fontWeightMedium);
  --BottomToolbarFontSize: calc(0.475vw + 0.844svh);
  --BottomToolbarFontColor: var(--fontWhite);
  --bgBottomToolbarHighlightedButton: var(--bgMedium);
  --BottomToolbarSeparator: var(--bgSeparator);

  --bgTopNavbar: var(--bgDark);
  --TopNavbarOpacity: 0;

  --bgCards: var(--bgDark);
  --CardsOpacity: var(--opacityLow);
  --CardsFontFamily: var(--fontFamilyRobotoMedium);
  --CardsFontWeight: var(--fontWeightMedium);
  --CardsFontSize: calc(0.65vw + 1.15svh);
  --CardsFontColor: var(--fontWhite);
  
  --CardsContentFontFamily: var(--fontFamily1);
  --CardsContentFontWeight: var(--fontWeightRegular);
  --CardsContentFontSize: calc(0.405vw + 0.72svh);
  --CardsContentLineHeight: var(--contentLineHeight);
  
  --bgAchievedThroughButton: var(--bgMedium);
  --AchievedThroughButtonOpacity: var(--opacityHigh);
  --AchievedThroughButtonFontFamily: var(--fontFamily1);
  --AchievedThroughButtonFontWeight: var(--fontWeightBold);
  --AchievedThroughButtonFontSize: calc(0.4vw + 0.71svh);
  --AchievedThroughButtonFontColor: var(--fontWhite);
  
  --bgCarousel: var(--bgDark);
  --CarouselOpacity: var(--opacityLow);
  --CarouselFontFamily: var(--fontFamilyRobotoMedium);
  --CarouselFontWeight: var(--fontWeightMedium);
  --CarouselFontSize: calc(0.677vw + 1.2svh);
  --CarouselFontColor: var(--fontWhite);

  --CarouselContentFontFamily: var(--fontFamily1);
  --CarouselContentFontWeight: var(--fontWeightRegular);
  --CarouselContentFontSize: calc(0.469vw + 0.834svh);
  --CarouselContentLineHeight: var(--contentLineHeight);

  --bgHamburgerMenuList: var(--bgDark);
  --HamburgerMenuListOpacity: var(--opacityLow);
  --HamburgerMenuListFontFamily: var(--fontFamily1);
  --HamburgerMenuListFontWeight: var(--fontWeightRegular);
  --HamburgerMenuListFontSize: calc(0.4165vw + 0.74svh);
  --HamburgerMenuListFontColor: var(--fontWhite);
  
  --bgHamburgerMenuListHover: var(--bgDark);
  
  --bgHotspotHover: var(--bgMedium);
  --HotspotHoverOpacity: var(--opacityLow);
  --HotspotHoverFontFamily: var(--fontFamilyRobotoMedium);
  --HotspotHoverFontWeight: var(--fontWeightMedium);
  --HotspotHoverFontSize: calc(0.4vw + 0.71svh);
  --HotspotHoverLabelFontSize: calc(0.521vw + 0.926svh);
  --HotspotHoverFontColor: var(--fontWhite);
  
  --bgOnboardingCard: var(--bgWhite);
  --bgOnboardingCardImg: var(--bgGrey);
  --OnboardingCardOpacity: var(--opacityOpaque);
  --OnboardingCardFontFamily: var(--fontFamilyRobotoMedium);
  --OnboardingCardFontWeight: var(--fontWeightMedium);
  --OnboardingCardFontSize: calc(1vw + 0.834svh);
  --OnboardingCardFontColor: var(--bgMedium);

  --OnboardingCardContentFontFamily: var(--fontFamily1);
  --OnboardingCardContentFontWeight: var(--fontWeightRegular);
  --OnboardingCardContentFontSize: calc(0.65vw + 0.648svh);
  --OnboardingCardContentFontColor: var(--bgMedium);
  --OnboardingCardNumberingFontColor: var(--bgLight);
  --bgOnboardingCardHighlightedButton: var(--bgDark);
  --OnboardingCardContentLineHeight: var(--contentLineHeight);

  --bgUcStory: var(--bgDark);
  --bgUcStoryNextPrevButton: var(--bgDark);
	--bgUcStoryHighlight: var(--bgShadow);
  --UcStoryOpacity: var(--opacityHigh);
  --UcStoryFontFamily: var(--fontFamilyRobotoMedium);
  --UcStoryFontWeight: var(--fontWeightMedium);
  --UcStoryFontSize: calc(0.8335vw + 1.481svh);
  --UcStoryFontColor: var(--fontWhite);

  --bgUcStoryBlocks: var(--bgLight);
  --UcStoryBlocksFontFamily: var(--fontFamilyRobotoBold);
  --UcStoryBlocksFontWeight: var(--fontWeightRegular);
  --UcStoryBlocksFontSize: calc(0.55vw + 0.98svh);

  --UcStoryBlocksContentFontFamily: var(--fontFamily1);
  --UcStoryBlocksContentFontWeight: var(--fontWeightRegular);
  --UcStoryBlocksContentFontSize: calc(0.4165vw + 0.74svh);
  --UcStoryBlocksContentLineHeight: var(--contentLineHeight);
  --UcStoryBlocksContentFontColor: var(--fontBlack);

  --bgSpinner: var(--bgDark);

	
}